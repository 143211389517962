<!-- 各类信息头部部分 -->
<template>
    <div class="header">
        <div class="header_left">
            <!-- 用来划线   -->
            <em class="header_em"></em>
            <div
                style="
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-left: 5px;
                "
            >
                <div
                    style="
                        font-size: 16px;
                        font-weight: bold;
                        margin-bottom: 5px;
                    "
                >
                    {{ titleInfo.title }}
                </div>
                <div>{{ titleInfo.english }}</div>
            </div>
        </div>
        <div class="more" v-if="toAddressIndex">
            <a
                href="javascript:void(0)"
                style="color: #444"
                @click="toDetail()"
            >
                MORE >
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: ["titleInfo", "toAddressIndex"],
    data() {
        return {};
    },
    methods: {
        toDetail() {
            this.$emit("toDetail", this.toAddressIndex);
        },
    },
};
</script>

<style scoped>
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header_left {
    display: flex;
    height: 75px;
    align-items: center;
}

.header_em {
    background: #6477f9;
    border-radius: 10px;
    width: 5px;
    height: 36px;
}
</style>
