<!-- 广告详情页首页 -->
<template>
    <div>
        <el-skeleton :loading="loading" animated>
            <template slot="template">
                <el-row>
                    <el-col :span="18" :offset="3">
                        <el-skeleton-item
                            variant="image"
                            style="height: 300px"
                        />
                    </el-col>
                </el-row>
            </template>
            <!-- 轮播图 -->
            <template>
                <el-row>
                    <el-col :span="18" :offset="3">
                        <el-image
                            :src="'https://www.olofamily.cn/Images/OCM/bn.jpg'"
                        ></el-image>
                    </el-col>
                </el-row>
            </template>
        </el-skeleton>
        <!-- 第一栏 -->
        <el-row>
            <el-col :span="18" :offset="3">
                <el-card body-style="display:flex;max-height:350px">
                    <!-- 公司介绍 -->
                    <div class="one_of_three">
                        <ad-header
                            :titleInfo="companyProfile"
                            :toAddressIndex="1"
                            @toDetail="changeTabKey"
                        />
                        <div class="introduction hideScrollBar">
                            <markdown-editor
                                mode="preview"
                                preview-background="#ffffff"
                                font-size="12px"
                                v-model="detailList.profile"
                            ></markdown-editor>
                        </div>
                    </div>
                    <!-- 优势业务 -->
                    <div
                        class="one_of_three"
                        style="display: flex; flex-direction: column"
                    >
                        <ad-header
                            :titleInfo="businessAdvantage"
                            :toAddressIndex="2"
                            @toDetail="changeTabKey"
                        />
                        <div class="my_slide hideScrollBar">
                            <markdown-editor
                                mode="preview"
                                preview-background="#ffffff"
                                font-size="12px"
                                v-model="detailList.superiority"
                            ></markdown-editor>
                        </div>
                    </div>
                    <!--公司风貌 -->
                    <div class="company_view_wrap" style="width: 33.3%">
                        <ad-header
                            :titleInfo="recruitmentInformation"
                            :toAddressIndex="1"
                            @toDetail="changeTabKey"
                        />

                        <!-- 有多张图片时 -->
                        <div
                            class="show-imgs-container"
                            v-if="mediaNews && mediaNews.length > 1"
                        >
                            <el-carousel
                                :interval="cutTime"
                                arrow="always"
                                :autoplay="autoplay"
                                trigger="click"
                                indicator-position="none"
                                @change="getIndex"
                            >
                                <el-carousel-item
                                    v-for="item in mediaNews"
                                    :key="item.uri"
                                >
                                    <el-image
                                        :src="item.uri"
                                        alt=""
                                        v-if="item.type === 2"
                                        class="carousel-img"
                                        :preview-src-list="[item.uri]"
                                    ></el-image>
                                    <!--视频播放器 -->
                                    <video-player
                                        v-else-if="item.type === 3"
                                        @ended="onPlayerEnded($event)"
                                        @loadeddata="onPlayerLoadeddata($event)"
                                        :ref="`videoPlayer`"
                                        playsinline
                                        :options="{
                                            ...playerOptions,
                                            sources: [
                                                {
                                                    type: `video/mp4`,
                                                    src: item.uri,
                                                },
                                            ],
                                        }"
                                    ></video-player>
                                </el-carousel-item>
                            </el-carousel>
                        </div>

                        <!-- 只有一张图片时 -->
                        <template v-if="mediaNews && mediaNews.length === 1">
                            <div
                                v-if="mediaNews[0].type === 2"
                                style="height: 200px"
                            >
                                <img
                                    :src="mediaNews[0].uri"
                                    class="one-page-img"
                                    style="border-radius: 4px"
                                />
                            </div>

                            <div v-else class="one-page-container">
                                <video-player
                                    ref="videoPlayer"
                                    playsinline
                                    :options="{
                                        ...playerOptions,
                                        sources: [
                                            {
                                                type: `video/mp4`,
                                                src: mediaNews[0].uri,
                                            },
                                        ],
                                        controlBar: {
                                            timeDivider: true, // 当前时间和持续时间的分隔符
                                            durationDisplay: true, // 显示持续时间
                                            remainingTimeDisplay: false, // 是否显示剩余时间功能
                                            fullscreenToggle: true, // 是否显示全屏按钮
                                        },
                                    }"
                                ></video-player>
                            </div>
                        </template>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <!-- 第二栏 -->
        <el-row class="top_margin">
            <el-col :span="18" :offset="3">
                <el-card body-style="display:flex;min-height:300px">
                    <!-- 运价 -->
                    <div style="flex: 1">
                        <ad-header
                            :titleInfo="priceService"
                            :toAddressIndex="2"
                            @toDetail="changeTabKey"
                        ></ad-header>
                        <el-table
                            :data="detailList.supServices"
                            height="250"
                            border
                            style="width: 100%"
                        >
                            <el-table-column
                                prop="name"
                                label="服务名称"
                            ></el-table-column>
                            <el-table-column
                                prop="price"
                                label="服务价格"
                            ></el-table-column>
                            <el-table-column
                                prop="profile"
                                label="服务简介"
                            ></el-table-column>
                        </el-table>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import AdHeader from "./AdDetailItemHeader.vue";
import MarkdownEditor from "@/components/common/MarkdownEditor.vue";
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
export default {
    components: {
        AdHeader,
        videoPlayer,
        MarkdownEditor,
    },
    // 接受父组件传值
    props: ["detailList", "mediaNews", "loading"],
    data() {
        return {
            // 浏览器宽度
            screenWidth: 0,
            drawer: true,
            companyProfile: {
                english: "Company Profile",
                title: "公司介绍",
            },
            businessAdvantage: {
                english: "Business Advantage",
                title: "业务优势",
            },
            recruitmentInformation: {
                english: "Company Views",
                title: "公司风貌",
            },
            companyNews: {
                english: "Company News",
                title: "公司新闻",
            },
            priceService: {
                english: "Price Service",
                title: "价格服务",
            },
            optionCustomer: {
                step: 1,
                limitMoveNum: 7, //限制开始滚动的条数
                openTouch: false,
                waitTime: 50, //滚动的速度
                direction: 1, //方向 0 往下 1 往上 2向左 3向右
                singleWidth: 30,
            },
            fakeData: [],
            adVantageIndex: 0,
            tableData: [],
            autoplay: true, //是否自动切换
            cutTime: 3000, //轮播时间，单位毫秒
            nowIndex: 0,

            // 播放器配置项
            playerOptions: {
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                autoplay: false, // 如果为true,浏览器准备好时开始回放
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 是否视频一结束就重新开始。
                preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: "zh-CN",
                aspectRatio: "2:1", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [
                    {
                        type: "video/mp4", // 类型
                        src: "", // url地址
                    },
                ],
                poster: "", // 封面地址
                notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true, // 是否显示全屏按钮
                },
            },
        };
    },
    methods: {
        changeTabKey(key) {
            this.$emit("onChangeTabKey", key);
        },

        toDetail(e) {
            // 修复点击失败,把方法委托给父级
            const item = e.target.closest(".ad_item");
            if (item) {
                const { info } = item.dataset;
                this.$store.commit("info/SET_RECRUITMENT_INFO", info);
                this.$router.push("/recruitmentInfo");
            }
        },

        onPlayerLoadeddata(player) {
            // 获取资源总时长
            this.duration = player.cache_.duration;
        },
        //监听媒体是否已到达结尾，播放完
        onPlayerEnded(player) {
            this.autoplay = true;
            this.interval = 3000;
        },

        //获取走马灯的索引
        getIndex(index) {
            //用户可能在上个视频未播放完毕就切换
            //此时需要暂停上个视频并把时间归零，否则影响对当前视频的监听逻辑
            if (this.mediaNews[index].type === 3) {
                this.$refs.videoPlayer[0].player.src(this.mediaNews[index].uri); // 重置进度条,再次轮播时让视频重新播放
                this.interval = this.duration * 1000;
                this.autoplay = false;
            }
        },
    },
};
</script>

<style scoped>
.ad_box {
    width: 100%;
    display: inlile-block;
    border: 1px solid #ddd;
    box-sizing: border-box;
    margin-left: 0.5%;
    border-radius: 5px;
}

.ad_item {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    height: 20px;
    list-style: none;
    /* cursor:pointer; */
}

.ad_scroll {
    margin: 0 10px;
    width: 93%;
    height: 190px;
    min-height: 25px;
    line-height: 25px;
    overflow: hidden;
    display: inline-block;
}

.icon {
    display: block;
    float: left;
    width: 4px;
    height: 4px;
    vertical-align: middle;
    background-color: #ff8f28;
    margin: 10px 10px 0 10px;
}

.ad_content {
    width: 70%;
    font-size: 14px;
    height: 20px;
    display: inline-block;
    color: #428bca;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.one_of_three {
    width: 33.3%;
    margin-right: 20px;
}

.image {
    height: 100px;
    max-width: 300px;
}

.introduction {
    width: 100%;
    overflow: scroll;
    flex-grow: 1;
    max-height: 270px;
    display: -webkit-box;
}

.hideScrollBar ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}

.hideScrollBar {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.top_margin {
    margin-top: 20px;
}

.news_title {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
}

.news_info {
    margin: 10px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #666666;
    padding: 10px;
    border-bottom: #666666 1px dashed;
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}

.my_slide {
    width: 100%;
    flex-grow: 1;
    max-height: 270px;
    display: flex;
    overflow: scroll;
    display: block;
    border: 2px #e2e9f9 solid;
    border-radius: 5px;
    margin-left: 5px;
}

.active {
    background-color: #fff;
}

.carousel-img {
    width: 100%;
    height: inherit;
}

.show-imgs-container {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.one-page-container {
    width: calc(100%-40px);
    height: 0;
    padding: 0;
    padding-bottom: 50%;
}

.one-page-img {
    width: 100%;
    height: inherit;
}

.company_view_wrap,
.one_of_three {
    display: flex;
    flex-direction: column;
}

.el-carousel {
    width: 100%;
}

::v-deep .show-imgs-container .el-carousel__container {
    width: 100%;
    height: 0;
    padding: 0;
    padding-bottom: 50%;
}

.el-carousel__item {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
</style>
