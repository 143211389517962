<!-- 优势与运价 -->
<template>
    <div class="ad_detail">
        <el-row class="top_margin">
            <el-col :span="18" :offset="3">
                <el-card body-style="display:flex">
                    <div style="margin-right: 10px">
                        <ad-header :titleInfo="businessAdvantage" />
                        <markdown-editor
                            mode="preview"
                            preview-background="#ffffff"
                            font-size="12px"
                            v-model="detailList.superiority"
                            :box-shadow="false"
                            :border="false"
                        ></markdown-editor>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-row class="top_margin">
            <el-col :span="18" :offset="3">
                <el-card body-style="display:flex;min-height:150px">
                    <div style="width: 100%">
                        <ad-header :titleInfo="priceService"></ad-header>

                        <el-table
                            :data="detailList.supServices"
                            height="250"
                            border
                            style="width: 100%"
                        >
                            <el-table-column
                                prop="name"
                                label="服务名称"
                            ></el-table-column>
                            <el-table-column
                                prop="price"
                                label="服务价格"
                            ></el-table-column>
                            <el-table-column
                                prop="profile"
                                label="服务简介"
                            ></el-table-column>
                        </el-table>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import MarkdownEditor from "@/components/common/MarkdownEditor.vue";
import AdHeader from "./AdDetailItemHeader.vue";
export default {
    props: ["detailList"],
    components: {
        AdHeader,
        MarkdownEditor,
    },
    data() {
        return {
            workerlist: {
                name: "",
                phone: "",
                qq: "",
            },
            businessAdvantage: {
                english: "Business advantage",
                title: "业务优势",
            },
            contactInfo: {
                english: "Contact info",
                title: "联系方式",
            },
            priceService: {
                english: "Price service",
                title: "价格服务",
            },
            tableData: [],
        };
    },
};
</script>

<style lang="stylus" scoped>
.ad_detail {}

.tab_key {
    padding: 0 30px;
    text-decoration: none;
    font-size: 16px;
    color: #444;
    line-height: 75px;
    font-weight: 600
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}

.top_margin {
    margin-top: 20px;
}

.introduction {
    font-size: 16px;
}

.image {
    height: 150px;
    max-width: 500px
}

.big_font {
    font-size: 16px;
    line-height: 24px;
}
</style>
