<template>
    <div class="ad_detail_contact">
        <el-row class="top_margin">
            <el-col :span="18" :offset="3">
                <el-card>
                    <ad-header :titleInfo="contactUs"></ad-header>
                    <el-row>
                        <el-col :span="12" class="big_font">
                            电话: {{ detailList.phone }}
                        </el-col>
                        <el-col :span="12" class="big_font">
                            邮箱: {{ detailList.email }}
                        </el-col>
                        <el-col :span="12" class="big_font">
                            网站：
                            <a :href="detailList.url">{{ detailList.url }}</a>
                        </el-col>
                        <el-col :span="12" class="big_font">
                            地区: {{ detailList.position }}
                        </el-col>
                        <el-col :span="12" class="big_font">
                            地址: {{ detailList.address }}
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-row class="top_margin">
            <el-col :span="18" :offset="3">
                <el-card>
                    <ad-header :titleInfo="cooperation"></ad-header>
                    <!-- 每一个人的名信片信息 -->
                    <el-row>
                        <el-col
                            v-for="(item, dIndex) in detailList.supContactList"
                            :key="item.requirement + Math.random()"
                            :style="{
                                width: '49%',
                                marginLeft: dIndex % 2 !== 0 ? '2%' : 0,
                            }"
                        >
                            <div
                                class="business_card_wrap"
                                style="display: flex; padding: 10px"
                            >
                                <div class="business_card_wrap_left">
                                    <div
                                        class="business_icon"
                                        :style="{
                                            backgroundImage: `url(${logoUrl})`,
                                        }"
                                    ></div>
                                </div>
                                <div class="business_card_wrap_right">
                                    <div class="right_header_position">
                                        {{ item.position }}
                                    </div>
                                    <el-row>
                                        <el-col
                                            :span="12"
                                            class="right_content_item"
                                        >
                                            联系人: {{ item.name }}
                                        </el-col>
                                        <el-col
                                            :span="12"
                                            class="right_content_item"
                                        >
                                            邮箱: {{ item.email }}
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col
                                            :span="12"
                                            class="right_content_item"
                                        >
                                            电话: {{ item.phone }}
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import AdHeader from "./AdDetailItemHeader.vue";
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
    components: { AdHeader, vueSeamlessScroll },
    props: ["detailList"],
    data() {
        return {
            workerlist: {
                name: "",
                phone: "",
                qq: "",
            },
            contactUs: {
                title: "联系方式",
                english: "Contact Us",
            },
            cooperation: {
                title: "业务合作",
                english: "Welcome cooperation",
            },
            optionCustomer: {
                step: 1,
                limitMoveNum: 5, //限制开始滚动的条数
                openTouch: false,
                waitTime: 50, //滚动的速度
                direction: 2, //方向 0 往下 1 往上 2向左 3向右
            },
        };
    },
    computed: {
        logoUrl() {
            return this.detailList.supResourceList.filter(
                (item) => item.type === 1,
            )[0]?.uri;
        },
    },
    methods: {
        query() {
            if (this.detailList.workerlist) {
                this.workerlist = {
                    name: this.detailList.workerlist[0].name,
                    phone: this.detailList.workerlist[0].phone,
                    qq: this.detailList.workerlist[0].qq,
                };
            }
        },
    },
    mounted() {
        this.query();
    },
};
</script>
<style scoped lang="stylus">
.top_margin{
    margin-top:20px;
}
.ad_detail_contact{
    .business_card_wrap{
        display: flex;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid rgb(235,238,245);
        box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
    }
    .business_card_wrap_left{
        width: 130px;
        height: 130px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        border:1px solid rgba(0,0,0,0.1);
        .business_icon{
            width: 110px;
            height: 110px;
            margin: 10px;
            background-size: contain;
            background-position:center;
            background-repeat: no-repeat;
        }
    }
    .business_card_wrap_right{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding:0 10px 0 20px;
        .right_content_item {
            font-size: 16px;
        }
        .right_header_position {
            height: 30px;
            line-height: 30px;
            font-size: 20px;
            font-weight: bold;
        }
    }
}
.big_font{
    font-size:16px;
    line-height: 2;
}
.contact_scroll{
    width:100%;
    overflow:hidden;
    display:inline-block
}
</style>
