<!-- 广 供应商目录页面详情的tabbar组件 -->
<template>
    <el-row class="line">
        <el-card body-style="display:flex;padding:10px">
            <el-col :span="18" :offset="3">
                <div style="display: flex; justify-content: space-between">
                    <div
                        style="display: flex; height: 75px; align-items: center"
                    >
                        <!-- 使用父组件传下来的信息显示 -->
                        <div
                            class="adTabBar_icon"
                            :style="{
                                backgroundImage:
                                    detailList && logoUrl
                                        ? `url(${logoUrl})`
                                        : '',
                            }"
                        ></div>
                        <!-- <img :src="detailList?detailList.image:undefined" style="width:75px;height:75px;"/> -->
                        <div
                            style="
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                margin-left: 30px;
                                align-items: center;
                            "
                        >
                            <div style="font-weight: bold; font-size: 30px">
                                {{ detailList ? detailList.name : "" }}
                            </div>
                        </div>
                    </div>
                    <div style="display: flex">
                        <!-- 动态绑定active绑定样式 -->
                        <a
                            href="javascript:void(0)"
                            v-for="(item, index) in tabKeyList"
                            :key="index"
                            @click="changeTabKey(index)"
                            :class="{ active: tabKeyValue === index }"
                            class="tab_key"
                        >
                            {{ item }}
                        </a>
                    </div>
                </div>
            </el-col>
        </el-card>
    </el-row>
</template>
<script>
export default {
    // 接受父组件传值
    props: ["detailList", "tabKeyValue"],
    data() {
        return {
            tabKeyList: ["首页", "关于我们", "优势与服务", "联系我们"],
        };
    },
    computed: {
        logoUrl() {
            return this.detailList.supResourceList.filter(
                (item) => item.type === 1,
            )[0]?.uri;
        },
    },
    methods: {
        // 将值传给父组件
        changeTabKey(params) {
            if (this.tabKeyValue === params) return;
            this.$emit("onChangeTabKey", params);
        },
    },
};
</script>
<style scoped>
.adTabBar_icon {
    width: 75px;
    height: 75px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.tab_key {
    padding: 0 30px;
    text-decoration: none;
    font-size: 16px;
    color: #444;
    line-height: 75px;
    font-weight: 600;
}

.active {
    border-bottom: 2px solid #409eff;
}

.line {
    /* border-bottom:4px solid #6477f9; */
}
</style>
