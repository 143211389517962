<template>
    <div class="ad_detail">
        <!-- 菜单组件 -->
        <ad-menu
            :detailList="detailList"
            @onChangeTabKey="changeTabKey"
            :tabKeyValue="tabKeyValue"
        ></ad-menu>
        <!-- 因为tab栏经常变化，所以使用v-show而不是v-if -->
        <!-- 首页组件 -->
        <ad-home
            v-show="tabKeyValue === 0"
            :detailList="detailList"
            @onChangeTabKey="changeTabKey"
            :mediaNews="mediaNews"
            :loading="loading"
        ></ad-home>
        <!-- 关于我们组件 -->
        <ad-about
            v-show="tabKeyValue === 1"
            :detailList="detailList"
            :mediaNews="mediaNews"
        ></ad-about>
        <!-- 联系我们组件 -->
        <ad-contact v-show="tabKeyValue === 3" :detailList="detailList" />
        <!-- 公司新闻组件
        <ad-news-list v-show="tabKeyValue===3" :detailList="detailList"></ad-news-list> -->
        <!-- 运价优势组件 -->
        <ad-fee-good v-show="tabKeyValue === 2" :detailList="detailList" />
    </div>
</template>

<script>
// 菜单栏组件
import AdMenu from "./component/AdDetailTabBar.vue";
// 首页组件
import AdHome from "./component/AdDetailHome.vue";
// 关于我们组件
import AdAbout from "./component/AdDetailAbout.vue";
// 联系我们组件
import AdContact from "./component/AdDetailContact.vue";

// 运价与优势组件
import AdFeeGood from "./component/AdDetailAdvantageAndTrafficFee.vue";
import { queryAdDetail } from "@/api/advertisement";
export default {
    components: { AdMenu, AdHome, AdAbout, AdContact, AdFeeGood },
    data() {
        return {
            detailList: "",
            tabKeyValue: 0,
            mediaNews: [],
            loading: true,
        };
    },
    mounted() {
        this.queryDetail(this.$route.query.id);
    },
    destroyed() {
        document.title = "放舱网";
    },
    methods: {
        // 修改菜单选中值
        changeTabKey(params) {
            this.tabKeyValue = params;
        },
        queryDetail(id) {
            //获取详情页信息
            queryAdDetail(id)
                .then(({ data }) => {
                    this.detailList = data.data;
                    const mediaNews = [];

                    this.detailList.supResourceList &&
                        this.detailList.supResourceList.length > 0 &&
                        this.detailList.supResourceList.forEach((item) => {
                            if (item.type === 2) {
                                mediaNews.push(item);
                            } else if (item.type === 3) {
                                mediaNews.unshift(item);
                            }
                        });

                    this.mediaNews = mediaNews;
                    document.title = this.detailList.name;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style scoped>
.ad_detail {
    margin-bottom: 50px;
}

.p_title {
    display: block;
    border-left: 4px solid rgba(168, 0, 0, 1);
    background-color: rgba(240, 160, 160, 0.1);
    text-indent: 12px;
    padding: 5px;
    font-size: 16px;
    font-weight: bold;
}

.to_left {
    float: left;
    width: 60%;
}

.to_right {
    width: 36%;
    float: right;
}

.birefly_text {
    font-size: 15px;
    text-indent: 3rem;
}

.theborder {
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
}

.service_text {
    font-size: 18px;
    text-indent: 3rem;
}

.back_div {
    display: inline-block;
    font-size: 20px;
    padding: 2rem;
    box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
    background: #dcdcdc;
}

.span-border {
    padding: 5px;
    font-size: 10px;
    margin-right: 1rem;
    border: 1px solid rgba(168, 0, 0, 1);
    border-radius: 5px;
}

.people_div {
    display: inline-block;
    margin: 2rem;
    box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
    background: #dcdcdc;
    font-size: 15px;
}

.people_span {
    display: block;
    margin: 10px 15px 10px 20px;
}

.video_div {
    height: 30rem;
    width: 40rem;
}
.img_div {
    margin-top: 10px;
    height: 300px;
    width: 450px;
}
</style>
