<!-- 广告详情页关于我们 -->
<template>
    <div class="ad_detail">
        <el-row class="top_margin">
            <el-col :span="18" :offset="3">
                <el-card>
                    <div>
                        <ad-header :titleInfo="companyProfile" />
                        <div style="display: flex">
                            <div class="image">
                                <img
                                    src="@/assets/img/introduction.jpg"
                                    alt=""
                                    class="image"
                                />
                            </div>
                            <div class="introduction">
                                <markdown-editor
                                    mode="preview"
                                    preview-background="#ffffff"
                                    font-size="12px"
                                    v-model="detailList.profile"
                                    :box-shadow="false"
                                    :border="false"
                                ></markdown-editor>
                            </div>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-row class="top_margin">
            <el-col :span="18" :offset="3">
                <el-card body-style="display:flex;min-height:150px">
                    <div style="margin-right: 20px">
                        <ad-header
                            :titleInfo="recruitmentInformation"
                        ></ad-header>
                        <div class="img_list">
                            <div
                                v-for="item in detailList.supJobList"
                                :key="item.id"
                                style="padding: 20px 0"
                            >
                                <div>
                                    <el-descriptions
                                        border
                                        :column="2"
                                        :title="item.position"
                                    >
                                        <el-descriptions-item label="招聘人数">
                                            {{ item.numbers }}
                                        </el-descriptions-item>
                                        <el-descriptions-item label="薪资">
                                            <span
                                                style="
                                                    color: red;
                                                    font-weight: bold;
                                                "
                                            >
                                                {{ item.salary }}
                                            </span>
                                        </el-descriptions-item>
                                        <el-descriptions-item
                                            label="需求"
                                            label-class-name="my-label"
                                        >
                                            {{ item.requirement }}
                                        </el-descriptions-item>
                                    </el-descriptions>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import AdHeader from "./AdDetailItemHeader.vue";
import MarkdownEditor from "@/components/common/MarkdownEditor.vue";
export default {
    props: ["detailList", "mediaNews"],
    components: {
        AdHeader,
        MarkdownEditor,
    },
    data() {
        return {
            companyProfile: {
                english: "Company Profile",
                title: "公司介绍",
            },
            contactInfo: {
                english: "Contact info",
                title: "联系方式",
            },
            recruitmentInformation: {
                english: "Recruitment information",
                title: "招聘信息",
            },
        };
    },
};
</script>

<style lang="stylus" scoped>
.my-label{
    background: #E1F3D8;
}
.border{
    cursor:pointer;
    padding:20px;
    position: relative;
    background-color: #fff;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.3),0px 0px 3px rgba(0,0,0,0.2) inset;
    -webkit-box-shadow:0px 0px 3px rgba(0,0,0,0.3),0px 0px 3px rgba(0,0,0,0.2) inset;
    -moz-box-shadow:0px 0px 3px rgba(0,0,0,0.3),0px 0px 3px rgba(0,0,0,0.2) inset;
    -o-box-shadow:0px 0px 3px rgba(0,0,0,0.3),0px 0px 3px rgba(0,0,0,0.2) inset;
}



.border:after,.border:before{
    position: absolute;
    content: '';
    top: 50%;
    bottom: 0px;
    left: 10px;
    right: 10px;
    z-index: -1;
    border-radius: 100px/10px;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.6);
    -webkit-box-shadow: 0px 0px 20px rgba(0,0,0,0.6);
    -moz-box-shadow: 0px 0px 20px rgba(0,0,0,0.6);
    -o-box-shadow: 0px 0px 20px rgba(0,0,0,0.6);
}
.ad_detail{

}

.tab_key{
    padding:0 30px;
    text-decoration: none;
    font-size:16px;
    color:#444;
    line-height:75px;
    font-weight:600
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
     background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
     background-color: #d3dce6;
  }

  .top_margin{
    margin-top:20px;
  }

  .introduction{
    flex-grow:1;
    margin-left: 10px;
    font-size:16px
  }

  .image{
    height:150px;
    max-width:500px
  }

  .big_font{
    font-size:16px;
    line-height:24px;
  }


  .carousel-img {
    width:100%;
    height:inherit;
  }


  .show-imgs-container {
    height: 100%;}

    .el-carousel {
        width: 100%;
        height:100%;
    }

    .el-carousel__container {
        width: 100%;
        height: 100%;
    }

    .el-carousel__item {
        width: 100%;
        height: 100%;
        border-radius: 10px;}
        .video {
            width:100%;
            height:100%;
            position: fixed;
            display: inline-block;
            vertical-align: baseline;
            object-fit: fill;
        }
        .play-btn{
            font-size: 60px;
            color: #fff;
            position: absolute;
            top:50%;
            left: 50%;
            transform: translate(-50%,-50%);}
            .play-btn:hover{
                cursor: pointer;
            }
</style>
